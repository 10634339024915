import "../Loader/Loader.scss"
import Logo from "../../assets/image/toruslogo.png"

const Loader = () => {
    return (
        <div className="w-screen h-screen flex justify-center items-center bg-[#00416a] loaderBack">
            <div>
                <div className="flex justify-center md:pb-0 pb-20">
                    <div id="page">
                        <div id="container">
                            <div id="ring"></div>
                            <div id="ring"></div>
                            <div id="ring"></div>
                            <div id="ring"></div>
                            <div className="text-white md:text-6xl text-3xl loaderText">TorusByte</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Loader