import { useEffect, useState } from "react";


const TermOfService = () => {
    const [shouldScrollToTop, setShouldScrollToTop] = useState(true);

    useEffect(() => {
        if (shouldScrollToTop) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            setShouldScrollToTop(false);
        }
    }, [shouldScrollToTop]);

    return (
        <div className="relative px-5 md:text-left text-center flex justify-center items-center">
            <div className="md:max-w-[1300px]">
                <div className="md:pt-[15%] pt-[35%] md:flex">
                    <div className="md:w-[60%]">
                        <h1 className="servicesText md:text-[60px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">TorusByte Terms of Service</h1>
                        <p className="servicesText md:text-[22px] text-[22px] mt-6 animate__animated animate__zoomIn animate__delay-1s">Welcome to TorusByte! These Terms of Service outline the rules and regulations for the use of TorusByte's website, products, and services.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">1. Acceptance of Terms</h1>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">By accessing or using TorusByte's website, products, or services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">2. User Accounts</h1>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">When you create an account with us, you are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">3. Use of Services</h1>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">You agree to use TorusByte's services for lawful purposes only and not to violate any laws in your jurisdiction. You also agree not to engage in any activities that may disrupt or interfere with the proper functioning of our services.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">4. Intellectual Property</h1>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">All content and materials available on TorusByte's website and services are the property of TorusByte or its licensors. You may not use, modify, reproduce, or distribute any of our content without prior written consent.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">5. Limitation of Liability</h1>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">TorusByte shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use of our website, products, or services.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">6. Changes to Terms</h1>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">We reserve the right to modify or replace these Terms of Service at any time. Any changes will be effective immediately upon posting. Continued use of our services after such changes constitutes acceptance of the new terms.</p>
                    </div>
                    <div className="px-10 md:mt-0 mt-10 animate__animated animate__zoomIn animate__delay-1s" id="serviceContainer">
                        <div className="md:block flex justify-center">
                            <div class="aboutLoader"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TermOfService