import "../CustomersTestimonies/CustomersTestimonies.scss"
import FavIcon from "../../assets/image/userPng.png"

const CustomersTestimonies = () => {
    return (
        <section id="testimonies">
            <div class="max-w-6xl mx-8 md:mx-10 lg:mx-20 xl:mx-auto">
                <div class="transition duration-500 ease-in-out  translate-x-0 translate-y-0 opacity-100">
                    <div class="mb-12 space-y-5 md:mb-16 md:text-center">
                        <h1 class="mb-5 leading-[50px] text-[40px] testimoniesTitle text-center md:text-[50px]">
                            It's not just us
                        </h1>
                        <p class="text-[22px] testimoniesTitle text-center md:text-[22px]">
                            Here's what others have to say about us
                        </p>
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
                    <ul class="space-y-8">
                        <li class="text-sm leading-6">
                            <div class="relative group">
                                <div class="relative p-6 space-y-6 leading-none rounded-lg bg-[#00416a8e]">
                                    <div class="flex items-center">
                                        <img src={FavIcon} class="w-28 ml-[-25px] opacity-80" alt="Kanye West" />
                                        <div>
                                            <h3 class="text-2xl ml-[-10px] testimoniesTitle">Domantas. M</h3>
                                        </div>
                                    </div>
                                    <p class="testimonialtext leading-normal text-gray-300 text-lg">From the initial consultation to the final delivery, TorusByte demonstrated professionalism, expertise, and creativity. </p>
                                </div>
                            </div>
                        </li>
                        <li class="text-sm leading-6">
                            <div class="relative group">
                                <div
                                    class="relative p-6 space-y-6 leading-none rounded-lg bg-[#00416a8e]">
                                    <div class="flex items-center">
                                        <img src={FavIcon} class="w-28 ml-[-25px] opacity-80" alt="Kanye West" />
                                        <div>
                                            <h3 class="text-2xl ml-[-10px] testimoniesTitle">Atomis UAB</h3>
                                            {/* <p class="text-white text-md">Rapper &amp; Entrepreneur</p> */}
                                        </div>
                                    </div>
                                    <p class="testimonialtext leading-normal text-gray-300 text-lg"> TorusByte understood our vision, and the team executed it flawlessly. We appreciate their attention to detail and commitment to delivering quality.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul class="space-y-8">
                        <li class="text-sm leading-6">
                            <div class="relative group">
                                <div
                                    class="relative p-6 space-y-6 leading-none rounded-lg bg-[#00416a8e]">
                                    <div class="flex items-center">
                                        <img src={FavIcon} class="w-28 ml-[-25px] opacity-80" alt="Kanye West" />
                                        <div>
                                            <h3 class="text-2xl ml-[-10px] testimoniesTitle">KICA.lt</h3>
                                            {/* <p class="text-white text-md">Rapper &amp; Entrepreneur</p> */}
                                        </div>
                                    </div>
                                    <p class="testimonialtext leading-normal text-gray-300 text-lg">We would like to thank TorusByte for wonderful communication manners, your help on the way to the project and for spending your valuable time.
                                        In personal communication with Deividas Kašėta, we were satisfied with all the insights, advice and sharing of information.
                                        We can definitely turn to you in the future if we need help.</p>
                                </div>
                            </div>
                        </li>
                        


                    </ul>
                    <ul class="space-y-8">
                        <li class="text-sm leading-6">
                            <div class="relative group">
                                <div
                                    class="relative p-6 space-y-6 leading-none rounded-lg bg-[#00416a8e]">
                                    <div class="flex items-center">
                                        <img src={FavIcon} class="w-28 ml-[-25px] opacity-80" alt="Kanye West" />
                                        <div>
                                            <h3 class="text-2xl ml-[-10px] testimoniesTitle">Matas. P</h3>
                                            {/* <p class="text-white text-md">Rapper &amp; Entrepreneur</p> */}
                                        </div>
                                    </div>
                                    <p class="testimonialtext leading-normal text-gray-300 text-lg">Deividas Kašeta and TorusByte made the entire process of getting a website for my small business easy and enjoyable. They took the time to understand my brand and the communication was fantastic. Highly recommended!</p>
                                </div>
                            </div>
                        </li>
                        

                        
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default CustomersTestimonies