import { useEffect, useState } from "react";

const PrivacyPolicy = () => {
    const [shouldScrollToTop, setShouldScrollToTop] = useState(true);

    useEffect(() => {
        if (shouldScrollToTop) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            setShouldScrollToTop(false);
        }
    }, [shouldScrollToTop]);

    return (
        <div className="relative px-5 md:text-left text-center flex justify-center items-center">
            <div className="md:max-w-[1300px]">
                <div className="md:pt-[15%] pt-[35%] md:flex">
                    <div className="md:w-[60%]">
                        <h1 className="servicesText md:text-[60px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">TorusByte Privacy Policy</h1>
                        <p className="servicesText md:text-[22px] text-[22px] mt-6 animate__animated animate__zoomIn animate__delay-1s">Welcome to TorusByte! At TorusByte, we are committed to protecting and respecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our website, products, or services.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">Information We Collect</h1>
                        <p className="servicesText md:text-[30px] text-[22px] mt-6 animate__animated animate__zoomIn animate__delay-1s">Personal Information</p>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">We may collect personal information, such as your name, email address, phone number, and other relevant details when you use our services, register an account, or contact us for support.</p>
                        <p className="servicesText md:text-[30px] text-[22px] mt-6 animate__animated animate__zoomIn animate__delay-1s">Usage Data</p>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">We collect information about how you interact with our website and services. This includes data about your device, IP address, browser type, and pages visited.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">How We Use Your Information</h1>
                        <ul className="text-white md:text-[20px] text-[22px] list-disc animate__animated animate__zoomIn animate__delay-1s">
                            <li>Providing and improving our services</li>
                            <li>Responding to your inquiries and support requests</li>
                            <li>Sending updates, newsletters, and promotional materials</li>
                            <li>Analyzing and improving the user experience</li>
                            <li>Ensuring the security and integrity of our systems</li>
                        </ul>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">Information Sharing</h1>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted service providers who assist us in operating our website and services.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">Security</h1>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">We take appropriate measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">Your Rights</h1>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">You have the right to access, correct, and delete your personal information. If you have any concerns or requests regarding your data, please contact us at info@torusbyte.com.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">Changes to This Privacy Policy</h1>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">We may update our Privacy Policy from time to time. Any changes will be reflected on this page, and the date of the last update will be revised accordingly.</p>
                        <h1 className="servicesText mt-20 md:text-[40px] md:leading-[65px] text-[40px] leading-[45px] animate__animated animate__zoomIn animate__delay-1s">Contact Us</h1>
                        <p className="servicesText md:text-[20px] text-[22px] mt-2 animate__animated animate__zoomIn animate__delay-1s">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at info@torusbyte.com. <br></br>Thank you for trusting TorusByte with your information.</p>
                    </div>
                    <div className="px-10 md:mt-0 mt-10 animate__animated animate__zoomIn animate__delay-1s" id="serviceContainer">
                        <div className="md:block flex justify-center">
                            <div class="aboutLoader"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PrivacyPolicy