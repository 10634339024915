import { FaCheck } from "react-icons/fa";
import "../WhyChooseUs/WhyChooseUs.scss"
import CommunicationPng from "../../assets/image/communication.png"
import IdeasPng from "../../assets/image/ideas.png"
import SupportPng from "../../assets/image/support.png"
import PartnershipPng from "../../assets/image/partnership.png"
import QualityPng from "../../assets/image/quality.png"
import LaunchPng from "../../assets/image/launch.png"

const WhyChooseUs = () => {
    return (
        <section class="mt-24">
            <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
                <div class="container mx-auto">
                    <div class="text-center flex justify-center">
                        <div>
                            <p class="mainTitle text-center relative leading-[50px] md:w-[800px] md:text-[50px] text-[40px] md:text-6xl md:pb-10">Why TorusByte?</p>
                            <p className="text-[22px] md:text-[22px] mainTitle md:mt-0 mt-5">Unleashing the Power of Precision in Digital Innovation</p>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-12 md:px-6 md:p-6 p-3 bg-[#00416a8e] rounded-2xl">
                        <div class="w-full border-b-[6px] md:w-1/2 border-r-[6px] lg:w-1/3 p-8">
                            <div class="flex items-center mb-6 md:text-left">
                                <img className="w-[70px]" src={IdeasPng} />
                                <div class="listText md:text-[28px] text-[24px] ml-5">Innovation at Every Turn</div>
                            </div>
                            <p class="descriptionText leading-loose text-white md:text-[19px] text-[17px]">
                                Stay ahead in the digital landscape with our commitment to innovation. We leverage the latest technologies, development methodologies, and design trends to create forward-thinking, future-proof solutions that give your business a competitive edge.
                            </p>
                        </div>
                        <div class="w-full border-b-[6px] md:w-1/2 lg:w-1/3 border-l-[6px] md:border-l-0 md:border-r-[6px] p-8">
                            <div class="flex items-center mb-6 md:text-left">
                                <img className="w-[70px]" src={CommunicationPng} />
                                <div class="listText md:text-[28px] text-[24px] ml-5">Transparent Communication</div>
                            </div>
                            <p class="descriptionText leading-loose text-white md:text-[19px] text-[17px]">
                                Stay informed and engaged throughout the development process. We prioritize transparent communication, providing regular updates and ensuring that you have full visibility into the progress of your project.
                            </p>
                        </div>
                        <div class="w-full border-b-[6px] border-r-[6px] md:border-r-0 md:w-1/2 lg:w-1/3 p-8">
                            <div class="flex items-center mb-6 md:text-left">
                                <img className="w-[70px]" src={SupportPng} />
                                <div class="listText md:text-[28px] text-[24px] ml-5">Reliable 24/7 Support</div>
                            </div>
                            <p class="descriptionText leading-loose text-white md:text-[19px] text-[17px]">
                                Your success is our priority, and that commitment extends beyond office hours. With round-the-clock support, we ensure prompt assistance, troubleshooting, and resolution of any issues, providing peace of mind and continuity for your business operations.
                            </p>
                        </div>
                        <div class="w-full border-b-[6px] md:w-1/2 lg:w-1/3 border-l-[6px] md:border-l-0 md:border-r-[6px] lg:border-b-0 p-8">
                            <div class="flex items-center mb-6 md:text-left">
                                <img className="w-[70px]" src={PartnershipPng} />
                                <div class="listText md:text-[28px] text-[24px] ml-5">Collaborative Partnership</div>
                            </div>
                            <p class="descriptionText leading-loose text-white md:text-[19px] text-[17px]">
                                At TorusByte, we believe in collaboration. Your vision is at the heart of our development process. We work closely with you to understand your goals, ensuring that every line of code and design element aligns seamlessly with your business objectives.
                            </p>
                        </div>
                        <div class="w-full border-b-[6px] md:w-1/2 border-r-[6px] lg:w-1/3 lg:border-b-0 p-8">
                            <div class="flex items-center mb-6 md:text-left">
                                <img className="w-[70px]" src={QualityPng} />
                                <div class="listText md:text-[28px] text-[24px] ml-5">Commitment to Quality</div>
                            </div>
                            <p class="descriptionText leading-loose text-white md:text-[19px] text-[17px]">
                                Quality is non-negotiable for us. From the initial concept to the final deployment, we adhere to the highest standards to deliver robust, reliable, and user-friendly applications that exceed expectations.
                            </p>
                        </div>
                        <div class="w-full border-l-[6px] md:border-l-0 md:w-1/2 lg:w-1/3 p-8">
                            <div class="flex items-center mb-6 md:text-left">
                                <img className="w-[70px]" src={LaunchPng} />
                                <div class="listText md:text-[28px] text-[24px] ml-5">Post-Launch Support</div>
                            </div>
                            <p class="descriptionText leading-loose text-white md:text-[19px] text-[17px]">
                                Our relationship doesn't end with the launch of your application. We provide comprehensive post-launch support, ensuring that your solution continues to perform optimally and evolves alongside your business.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default WhyChooseUs